
*{
  box-sizing: border-box;
  padding: 0%;
  margin: 0%;
}


.article{
  height: 100%;
  position: relative;
  overflow: hidden;
}

.bilde{
  object-fit: cover;
  width: 100%;
  height: 100%
}

.tekst{
  font-size: 80px;
  color: rgb(0, 0, 0);
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  margin: auto;
}