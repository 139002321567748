
body{
    font-family: sans-serif;
}

.projectsContainer{
    /*background-image: url("../../images/ProgImg/Kotlin_Icon.svg.png");
    background-blend-mode:color;*/
    height: 100%;
    width: 100%;
    background-color: rgba(237,239,244,255);
    overflow: hidden;
}


.projectsBoxContainer{
    width: 200vw;
    height: 100%;
    display: flex;
    padding-top: calc(5vh + 5vw);
    justify-content: center;
    text-align: center;
    transition: transform 0.3s ease;
    scroll-behavior: smooth;
}

.AllProjectsContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.BackendContainer{
    padding-top: 0px;
}

.backProjects{
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 70px;

}


.fullStackTextConatiner{
    height: 10%;
    position: absolute;
    justify-content: center;
    text-align: center;
}

.fullStackText{
    margin-top: 50px;
    font-size: calc(0.8vw + 0.8vh); /*1.6rem;*/
    color: #555;
    padding-bottom: 15px;
    font-weight: 600;
    letter-spacing: 0.05rem;
}

.strekUnderProjects{
    border: solid rgb(138, 43, 226) calc(0.10vw + 0.11vh);
    border-radius: 30px;
    width: 15%;
    margin: auto;
}

.topLineProjectContainer{
    width: 100%;
    position: absolute;
    margin-top: 100px;
    display: grid;
    display-items: center;

}

.firstTwoProjectsConatainer{
    display: flex;
    justify-content: center;
    transition: transform 0.3s ease;
    width: 100vw;

}


.projectsInfo{


    /*
    line-height: 1.2;
    font-weight: 100;
    font-size: calc(0.7vw + 0.7vh);
    color: #555;
    font-family: inherit;
    */
}
.workshopText{
    text-align: center;
    margin: auto;
}


.projectBox{
    position: relative;
    width: calc(14vw + 17vh); /*25vw*/
    height: calc(23vw + 20vh);
    background-color: #fff;
    margin: 150px;
    box-shadow: -20px 20px 5px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    display: block;
    cursor: pointer;
    overflow: hidden;
    color: #444;
}

.projectBox:hover{
    box-shadow: -30px 30px 10px rgba(0, 0, 0, 0.05);
    transition: 0.45s;
    transform: translateX(4px) translateY(-2px);
    color: red;
}

.projectNBA:hover{
    color: blue;
}

.projectAlgo:hover{
    color: blueviolet;
    /*filter: brightness(97%);*/
}

.projectRepute:hover{
    color:  #F10472;
}

.projectFilterImage:hover{
    color: sienna;
}

.projectHelsinki:hover{
    color: #4c751a;
}


.scroll-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateX(10%);
    transition: 0.2s;
    font-size: 34px;
    background: none;
    border: none;
    outline: none;
    right: 50px;
    color: #333; /* Set a color that contrasts well with your background */
  }
  
  .scroll-icon:hover {
    color: #555; /* Adjust the color on hover for visual feedback */
  }
 

.thirdProject{
    transition: transform 3.3s ease;

}

.imageOfProject{
    height: 40%;
    padding-top: 20px;
    width: auto;
}

.imageImageFiltering{
    width: 80%;
}

.ImageFilterImagesContainer {
    position: relative;
    display: flex; /* Enable flexbox */
    justify-content: center; /* Horizontally center the images */
    align-items: center; /* Vertically center the images */
  }
  
  .ImageFilteringImg {
    position: absolute; /* Ensure stacking works */
    width: auto; /* Maintain aspect ratio */
    height: 75%; /* Make image height match the container */
    transition: transform 0.4s ease, opacity 0.8s ease;
    border-radius: 10px;


  }

  .FilterImgTop {

    opacity: 0.4;
  }
  
  .FilterImgMiddle {
    opacity: 1; /* Invisible initially */

  }
  
  .FilterImgBottom {
    transform: translate(0); /* Move further down */
    opacity: 0.3; /* Invisible initially */

  }

  .projectFilterImage:hover .FilterImgTop{
    opacity: 0.9; /* Make visible */
    transform: translate(-15%, -12%); /* Bring to top */
  }
  
  .projectFilterImage:hover .FilterImgMiddle {
    opacity: 0.9; /* Make visible */
  }
  
  .projectFilterImage:hover .FilterImgBottom {
    transform: translate(17%, 20%); /* Move up */
    opacity: 0.9; /* Make visible */
  }

.helsinkiBusVideo {
    opacity: 0.7;
    transition: opacity 1s ease-in-out;
    background-color: transparent;
    position: relative;
    width: 100%;
    margin: auto;
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
  
.busVideo {
    width: 80%; /* Adjust the width as needed */
    margin-left: 55px;
    min-width: 1400px; /* Set a maximum width if necessary */
    min-height: 600px;
    max-width: max-content;
    max-height: max-content;
}
  
  
.projectBox.hovered .helsinkiBusVideo {
    opacity: 1;
}
  


.infoAboutProject{
    height: 50%;
    font-family: inherit;
    padding: 30px;
    padding-top: 32px;
    width: 100%;
    text-align: left;
}

/*
.infoAboutAnalyticsProject{
    padding: 14px 10px;
}*/

/*
.infoAboutFilterProject{
    padding: 10px 8px;
}
*/

.rettStrekProjects{
    width: 100%;
    border: solid rgb(237, 234, 234) 1.45px;
    margin-top: calc(0.45vw + 0.5vh);
    position: absolute;
    bottom: 0;
    margin-bottom: calc(1.5vh + 1.6vw);
}

.projectLanguages{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    margin-bottom: calc(0.3vh + 0.3vw);
}

.languageLogo{
    position: absolute;
    bottom: 0;
}

.languageBox{
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: calc(0.25vw + 0.25vh) calc(0.45vw + 0.45vh); /*9px 17px*/
    margin-right: calc(0.25vw + 0.25vh);
    background-color: rgb(231, 231, 227);
    color: #666;
    font-size: calc(0.5vh + 0.45vw);
    font-weight: 300;
}

.bildeAvOsloApp{
    max-height: fit-content;
    max-height: max-content;
    max-height: 100%;
    width: auto;
}


.headlineProjectInfo{
    font-family: inherit;
    font-size: calc(0.7vh + 0.7vw);
    font-weight: 100;
    padding-bottom: 8px;
    padding-left: 10px;
}

.headlineFilterAppInfo{
 
}

.infoOmOsloAppenText{
    line-height: 1.6;
    color: #666;
    font-size: calc(0.57vw + 0.57vh);
    padding-left: 10px;
}

.lead{
    padding-left: 10px;
}

.IconContainer{
    width: calc(0.4vw + 0.4vh);
    height: 100%;
    object-fit: contain;
    margin-right: calc(0.25vh + 0.25vw); /*5px*/

}



@media screen and (min-width: 2100px) {

    .projectsInfo{

    }

    .projectsBoxContainer{
        width: 193vw;
        height: 100%;
        display: flex;

    }
    .projectBox{
        margin: 160px;
    }

    .busVideo{
        min-width: 1350px; /* Set a maximum width if necessary */
        min-height: 720px;
        margin-left: 90px;

    }

    .IconContainer{
        height: 100%;
        width: calc(0.6vw + 0.6vh);

    
    }

    .middleIcon{
        width: calc(0.5vw + 0.4vh);
    }

    .projectLanguages{
        margin-bottom: calc(0.15vh + 0.20vw);
    }

    .languageBox{
        padding: 10px calc(0.40vw + 0.40vh); /*9px 17px*/
    }

}

@media screen and (min-width: 1800px) and (max-width: 2100px) {
    .languageBox{

        padding: calc(0.255vw + 0.27vh) calc(0.47vw + 0.45vh); /*9px 17px*/
        margin-right: calc(0.25vw + 0.25vh);
    }

    .busVideo{
        margin-left: 65px;
    }

}


@media screen and (max-height: 1400px){
    .projectBox{
        margin: 60px;
        height: calc(23vw + 21vh);
        width: calc(15vw + 16vh);
        margin-top: 150px;
    }
    
}


@media screen and (max-height: 1000px) {



    .imageOfProject{
        height: 30%;
        padding-top: 10px;
    }

    .infoAboutProject{
        height: 60%;
        font-family: inherit;
        padding: 20px;
        padding-top: 25px;
    }



    .workshopText{
        margin-top: -10px;
    }



    .fullStackText{
        font-size: 1.3rem;
    }


    .headlineOsloAppInfo{
        font-size: 1rem;
        padding-left: 10px;
    }

    .busVideo{
        min-width: 900px; /* Set a maximum width if necessary */
        min-height: 600px;
        margin-top: -50px;

    }

    .middleIcon{
        width: calc(0.5vw + 0.5vh);
    }
    
    .biggerIcon{
        width: calc(0.5vw + 0.5vh);
    }


}



@media screen and (max-width: 1200px){

    .backProjects{
        display: block;
    }

    .firstTwoProjectsConatainer{
        display: block;
    }

    .AllProjectsContainer{
        text-align: center;
    }

    .projectsBoxContainer{
        display: block;
        justify-content: center;
        text-align: center;
        margin: 0;
    }
    .projectBox{
        min-width: calc(13vh + 12vw);
        height: calc(18vw + 28vh);

        margin: 90px auto;
    }

    .fullStackTextConatiner{
        height: 10%;
        margin-top: -40px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        justify-content: center;
        text-align: center;
    }

    .fullStackText{
        font-size: calc(0.8vw + 0.8vh); /*1.6rem;*/
        color: #555;
        padding-bottom: 10px;
        font-weight: 600;
        letter-spacing: 0.05rem;
    }

    .strekUnderProjects{
        border: solid rgb(138, 43, 226) calc(0.12vw + 0.11vh);
        border-radius: 30px;
        width: 4%;
        margin: auto;
    }

    .imageOfProject{
        height: calc(7vh + 6vw);
    }

    .projectLanguages{

    }

    .middleIcon{
        width: calc(0.4vw + 0.4vh);
    }
    
    .biggerIcon{
        width: calc(0.4vw + 0.4vh);
    }


    .languageBox{
        /*margin-right: calc(0.15vw + 0.15vh);*/
    }

    .busVideo{
        min-width: 650px; /* Set a maximum width if necessary */
        min-height: 500px;
        margin-left: 70px;
        margin-top: -30px;

    }
}

