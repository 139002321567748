.HeroBg {
    content: '\A';
    position: absolute;
    width: 100%; 
    height: 100%;
    top:0; 
    left:0;
    position: relative;
    transition: all 1s;
    color: white;
    background:linear-gradient(rgba(20, 117, 150, 0.266), rgba(0, 0, 0, 0.559));
}

.HeroBg:hover{
    color: black;
}



.HeroBg:after {
    content: '\A';
    position: absolute;
    width: 100%; 
    height:100%;
    top:0; 
    left:0;
    background:linear-gradient(rgba(14, 70, 88, 0.48), rgba(0, 0, 0, 0.67));
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    color: black;

}
.HeroBg:hover:after {
    opacity: 1;

}
.HeroBg:hover:before {

}

.tcp{
    margin-left: 20px;
    color: white;
    font-weight: 100;
}

.hero{
    color:rgb(53, 196, 143);
    color: #0c0c0c;
    color: #f9f9f9;

}

.toplineV5{
    font-weight: 100;
    padding-top: 20px;
}


.heroText{
    width: 5px;
    padding: 5px;
    margin-left: 10px;
    opacity: 1;
}

.tcpV2{
    padding: 0;
    margin: 0;
    row-gap: 0;
}



.introduksjon{
    position: absolute;
    
    width: 50%;
    left: 0;
    padding-left: 40px;
    color: white;
    


}

.introduksjonTekst{
    font-size: 1.5rem;
    font-weight: 200;
    letter-spacing: 0.15rem;
    font-style: italic;
    width: 97%;
    line-height: 1.2;
}

.heroSocials{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    display: grid;
    width: 5rem;
}

.linkedinV2{
    width: 100%;
    padding-bottom: 10px;
}

.githubV2{
    padding-top: 10px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .introduksjonTekst{
        font-size: 1.1rem;
    }

    .linkedinV2{
        width: 80%;
    }

    .githubV2{
        width: 80%;
    }
}


.helloWorld{
    position: absolute;
    left: 0;
    margin: 0;
    margin-top: -200px;
    padding: 50px;
}

.overskriftHero{
    color: aliceblue;
    font-family: cursive;
    padding-bottom: 15px;
}

.ImageBg{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}




@media screen and (max-height: 850px) {
    .HeroBg{

        background:linear-gradient(rgba(14, 70, 88, 0.48), rgba(0, 0, 0, 0.67));
    }

    .introduksjonTekst{
        font-size: 1rem;
    }

    
    .linkedinV2{
        width: 100%;
    }

    .githubV2{
        width: 100%;
    }
}

