body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

.KnowledgeContainer {
    height: 100%;
    width: 100%;
    background-color: #1a1a1a;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 50%, transparent 100%);
    justify-content: center;
    text-align: center;
    position: relative;
    font-size: small;
    overflow: hidden;
}

.upperContainer {
    position: relative;
    padding-top: 70px;
    text-align: center;
    justify-content: center;
    margin: auto;
}


.upperContainer{
    position: relative;
    padding-top: 70px;
    text-align: center;
    justify-content: center;
    margin: auto;
}


.headlineContentProficiencies{
    position: relative; 
    justify-content: center;
    display: block;
    flex-direction: row;
    width: 100%;
    text-align: center;
    color: white;
}

.proficienciesHeadline{
    font-size: 2.2rem;
    letter-spacing: 0.05rem;  
    color: white;
}

.rettStrekProficiencies {
    position: relative;
    margin-bottom: 7.5px;
    width: 0.5rem;
    display: inline-block;
    border: solid white 0.0207in;
    transition: border-width 0.4s ease-out, width 0.5s ease-out; /* Overgang for bredde og grenselinjens tykkelse */
  }
  
.rettStrekProficiencies.animate {
    width: 7.5rem; /* Angi ønsket bredde */
    border-width: 0.0207in; /* Øk grenselinjens tykkelse */
}


.languages{
    padding-top: 70px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-columns: auto auto auto;
    z-index: 3;
    text-align: center;
    justify-content: center;
    margin: auto;
    right: 0;
    left: 0;
}

.languageBilde{
    width: 220px;
    opacity: 0.55;
    transition: transform 0.7s ease;
    z-index: 33;
}


.languageBilde::after, .languageBilde::before{
    
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    left:0;
    bottom: 0;
   
    z-index: -1;
    transition: transform 0.5s ease-in-out;
}

.languageBilde:hover{
    transform: translate(16px, -20px);
    width: 270px;
    opacity: 1;
}

.languageBilde:hover::after{
    transform: translate(6px, 6px);

}

.languageBilde:hover::before{
    transform: translate(12px, 12px);

}


.goalsContainer{
    position: relative;
    bottom: 0;
    justify-content: center;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: -5px;
    margin-top: 150px;
    padding-bottom: calc(2.5vh + 2.5vw);

}

.toplineGoals{
    color: aliceblue;
    padding: 20px;
    padding-top: 5rem;
    font-size: 2.0rem;
}


.experiencesContainer {
    display: grid;           /* Enables grid layout */
    place-items: center;     /* Centers both horizontally and vertically */
    width: 100%;             /* Full width of the screen */

  }
  
  .experienceSmallContainer {
    width: 80%;              /* Adjust as needed */
    max-width: min-content;        /* Prevent the box from getting too large */
    text-align: center;      /* Center the text inside */
    margin-top: 30px;
    margin-left: calc(-14.5vw - 15.5vh);
  }
 

.experienceBox{

    min-width: max-content;
    width: min-content;
    margin: auto;
    /*border: solid #555 2px;*/
    margin-bottom: 20px;
    margin-top: 23px;
    border-radius: 100px;
    transition: transform 0.2s, width 0.2s, font-size 0.1s; /* Adding width transition */
    color: #bcbcbc;
    display: flex;
    justify-content: left;
    align-items: left;
    padding: calc(0.7vw + 0.5vh);
    font-size: calc(0.7rem + 0.3vw); /* Responsive font size */
}

.experienceBox:hover{
    transform: translate(1px, -1px) scale(1.1);
    transition: 0.3s;
    cursor: pointer;
    color: white;
    background-color: #333;
}

.custom-underline {
    position: relative;
    text-decoration: none;
}

.custom-underline:hover{
    text-decoration: none;
}
  

.experienceText{}


.venstreInfo{
    border: solid #4A1097 2px;
    border-radius: 500px;
    width: 100%;
}

.info2{
    border: solid #180238 2px;
    margin-top: 400px;
}

.venstreInfoTekst{
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    position: absolute;
    color: white;
    margin-top: 150px;
    width: 75%;
    margin-left: -300px;
    z-index: -33;

}
.venstreInfoTekst2{
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    position: absolute;
    color: white;
    right: 0;
    width: 70%;
    margin-right: -640px;
    margin-top: -320px;

    z-index: -33;

}

.venstreInfoContainer{
    position: absolute;
    padding-top: 50px;
    max-width: 250px;
}

.venstreBilde{
    position: absolute;
    margin-right: -640px;
    right: 0;
    width: 70%;
    margin-top: 80px;
}

.venstreBilde2{
    position: absolute;
    width: 70%;
    left: 0;
    margin-left: -300px;
    margin-top: 250px;
}

.frontendContainer{

}

.middleInfoTekst{
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    position: absolute;
    color: aliceblue;
    max-width: 250px;

}

.venstreInfoTekstRight{
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    position: absolute;
    color: white;
    margin-top: 50px;
    width: 70%;
    margin-left: 50px;
    z-index: -33;

}

.middleTekst{
    margin-top: -50px;
}

.middleTekst2{
    margin-top: -80px;
}

.middleBilde{
    margin-right: -700px;
    margin-top: -50px;
}

.middleBilde2{
    margin-left: -280px;
    margin-top: 290px;
}

.hoyreInfoTekst{
    margin-top: 50px;
    max-width: 250px;
}

.hoyreBilde{
    margin-right: -690px;
    margin-top: 90px;
}



@media screen and (max-height: 1300px){

}
  
  


@media screen and (max-width: 1600px) {
    
    .languageBilde{
        width: 180px;
    }
    .languageBilde:hover{
        transform: translate(16px, -20px);
        width: 200px;
        opacity: 1;
    }

    .venstreInfo{
        border: solid #4A1097 2px;
        border-radius: 500px;
        width: 100%;
    }
    
    .info2{
        border: solid #180238 2px;
        margin-top: 400px;
    }
    
    .venstreInfoTekst{
        font-size: 12px;
        margin-top: 90px;
        width: 60%;
        margin-left: -180px;
    }

    .venstreInfoTekst2{
        font-size: 12px;
        right: 0;
        width: 65%;
        margin-right: -500px;
        margin-top: -260px;
    
        z-index: -33;
    
    }
    
    .venstreBilde{
        position: absolute;
        margin-right: -500px;
        right: 0;
        width: 60%;
        margin-top: 60px;
    }
    
    .venstreBilde2{
        position: absolute;
        width: 70%;
        left: 0;
        margin-left: -190px;
        margin-top: 210px;
    }    
    
    .venstreInfoTekstRight{
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        position: absolute;
        color: white;
        margin-top: 50px;
        width: 70%;
        margin-left: 50px;
        z-index: -33;
    
    }

    .middleBilde{
        width: 50%;
        margin-right: -600px;
        margin-top: -120px;
    }
    .middleBilde2{
        width: 50%;
        margin-left: -140px;
        margin-top: 275px;
    }

    .middleInfoTekst{
        font-size: 11px;
        position: absolute;
        color: aliceblue;
        width: 11vw;
        margin-left: -870px;
        margin-top: 220px;
    }

    .middleTekst2{

        font-size: 11px;
        margin-left: 950px;
        margin-top: -100px;
    }

    .hoyreBilde{
        margin-right: -600px;
        right: 0;
        width: 55%;
        margin-top: 90px;
    }

    .hoyreBilde2{
        margin-left: -80px;
        margin-top: 160px;
        width: 65%;
    }

    .hoyreInfoTekst{
        font-size: 11px;
        width: 85%;
        margin-left: -120px;
        margin-top: 190px;
    }

    .hoyreInfoTekst2{
        font-size: 11px;
        width: 75%;
        margin-left: -50px;
        margin-top: 130px;
    }

    .experienceText{

    }
}


@media screen and (max-width: 1450px) {
    

    .toplineGoals{
        font-size: 1.2rem;
    }

    .languageBilde{
        width: 180px;
    }
    .languageBilde:hover{
        transform: translate(16px, -20px);
        width: 200px;
        opacity: 1;
    }

    .venstreInfo{
        border: solid #4A1097 2px;
        border-radius: 500px;
        width: 100%;
    }
    
    .info2{
        border: solid #180238 2px;
        margin-top: 400px;
    }
    
    .venstreInfoTekst{
        font-size: 10px;
        margin-top: 80px;
        width: 60%;
        margin-left: -150px;
    }

    .venstreInfoTekst2{
        font-size: 10px;
        right: 0;
        width: 45%;
        margin-right: -440px;
        margin-top: -250px;
    
        z-index: -33;
    
    }
    
    .venstreBilde{
        position: absolute;
        margin-right: -450px;
        margin-top: 50px;
        right: 0;
        width: 60%;
    }
    
    .venstreBilde2{
        position: absolute;
        width: 70%;
        left: 0;
        margin-left: -170px;
        margin-top: 200px;
    }

    .middleInfoTekst{
        font-size: 11px;
        position: absolute;
        color: aliceblue;
        
    
    }
    
    .venstreInfoTekstRight{
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        position: absolute;
        color: white;
        margin-top: 50px;
        width: 70%;
        margin-left: 50px;
        z-index: -33;
    
    }

    .middleBilde{
        width: 50%;
        margin-right: -560px;
        margin-top: -140px;
    }
    .middleBilde2{
        width: 50%;
        margin-left: -90px;
        margin-top: 250px;
    }

    .middleInfoTekst{
        font-size: 10px;
        width: 75%;
        margin-left: -820px;
        margin-top: 200px;
    }

    .middleTekst2{
        width: 70%;
        font-size: 10px;
        margin-left: 920px;
        margin-top: -140px;
    }

    .hoyreBilde{
        margin-right: -560px;
        right: 0;
        width: 60%;
        margin-top: 70px;
    }

    .hoyreBilde2{
        margin-left: -60px;
        margin-top: 150px;
        width: 60%;
    }

    .hoyreInfoTekst{
        font-size: 10px;
        width: 75%;
        margin-left: -90px;
        margin-top: 195px;
    }

    .hoyreInfoTekst2{
        font-size: 10px;
        width: 60%;
        margin-left: -80px;
        margin-top: 140px;
    }
}


@media screen and (max-width: 980px)  {



    .languages{
        display: flex;
        flex-direction: column;
        width: 30%;

    }
    .languageBilde{
        width: 150px;
    }

    .languageBilde:hover{
        transform: translate(16px, -20px);
        width: 170px;
        opacity: 1;
    }

    .venstreInfo{
        border: solid #4A1097 2px;
        border-radius: 500px;
        width: 100%;
    }
    
    .info2{
        border: solid #180238 2px;
        margin-top: 400px;
    }
    
    .venstreInfoTekst{
        font-size: 8px;
        margin-top: 50px;
        width: 60%;
        margin-left: -130px;
    }

    .venstreInfoTekst2{
        font-size: 8px;
        right: 0;
        width: 40%;
        margin-right: -130px;
        margin-top: -130px;
    
        z-index: -33;
    
    }
    
    .venstreInfoContainer{
        position: absolute;
        padding-top: 50px;
    }
    
    .venstreBilde{
        position: absolute;
        margin-right: -150px;
        margin-top: 0px;
        right: 0;
        width: 50%;
        margin-top: 0px;
    }
    
    .venstreBilde2{
        position: absolute;
        width: 50%;
        left: 0;
        margin-left: -130px;
        margin-top: 200px;
    }
    
    .frontendContainer{
    
    }
    
    .middleInfoTekst{
        font-size: 14px;
        position: absolute;
        color: aliceblue;
        
    
    }
    
    .venstreInfoTekstRight{
        font-family: 'Courier New', Courier, monospace;
        font-size: 14px;
        position: absolute;
        color: white;
        margin-top: 50px;
        width: 50%;
        margin-left: 50px;
        z-index: -33;
    
    }

    .middleBilde{
        width: 50%;
        margin-right: -400px;
        margin-top: -50px;
    }
    .middleBilde2{
        width: 50%;
        margin-left: 100px;
        margin-top: 250px;
    }

    .middleInfoTekst{
        font-size: 8px;
        width: 55%;
        margin-left: -620px;
        margin-top: 320px;
    }

    .middleTekst2{

        font-size: 8px;
        margin-left: 780px;
        margin-top: -50px;
    }

    .hoyreBilde{
        margin-right: -580px;
        right: 0;
        width: 50%;
        margin-top: 50px;
    }

    .hoyreBilde2{
        margin-left: 290px;
        margin-top: 200px;
        width: 50%;
    }

    .hoyreInfoTekst{
        font-size: 7px;
        width: 50%;
        margin-left: 260px;
        margin-top: 200px;
    }

    .hoyreInfoTekst2{
        font-size: 7px;
        width: 50%;
        margin-left: -120px;
        margin-top: 100px;
    }

    .toplineGoals{
        font-size: 0;
    }

    .goals{
        width: 0px;
    }
}


@media screen and (max-width: 700px)  {

    .KnowledgeContainer{
        overflow: hidden;
        overflow-y: hidden;
    }

    .venstreInfoTekst{
        font-size: 7px;
        margin-top: 50px;
        width: 60%;
        margin-left: -100px;
    }

    .venstreInfoTekst2{
        font-size: 7px;
        right: 0;
        width: 45%;
        margin-right: -115px;
        margin-top: -200px;
    
        z-index: -33;
    
    }
    
    .venstreInfoContainer{
        position: absolute;
        padding-top: 50px;
    }
    
    .venstreBilde{
        margin-right: -120px;
        margin-top: 0px;
        right: 0;
        width: 45%;
        margin-top: -310px;
    }
    
    .venstreBilde2{
        width: 70%;
        left: 0;
        margin-left: -130px;
        margin-top: -200px;
    }

    .middleBilde{
        width: 40%;
        margin-right: -360px;
        margin-top: -600px;
    }
    .middleBilde2{
        width: 45%;
        margin-left: 100px;
        margin-top: 100px;
    }

    .middleInfoTekst{
        font-size: 6px;
        width: 50%;
        margin-left: -600px;
        margin-top: 150px;
    }

    .middleTekst2{
        width: 40%;
        font-size: 6px;
        margin-left: 750px;
        margin-top: 50px;
    }

    .hoyreBilde{
        margin-right: -560px;
        right: 0;
        width: 50%;
        margin-top: -100px;
    }

    .hoyreBilde2{
        margin-left: 300px;
        margin-top: -190px;
        width: 60%;
    }

    .hoyreInfoTekst{
        font-size: 6px;
        width: 40%;
        margin-left: 300px;
        margin-top: 220px;
    }

    .hoyreInfoTekst2{
        font-size: 6px;
        width: 40%;
        margin-left: -150px;
        margin-top: 120px;
    }

    .goals{
        width:0%
    }

    .toplineGoals{
        font-size: 0px;
    }
}
