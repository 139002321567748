
.projectsContainer{
    display: grid;
    height: 95vh;
    grid-template-columns: auto auto auto;
    background-color: #efebdf93;
    justify-content: center;
    text-align: center;
}


.textBox{
    justify-content: center;
    text-align: center;
    width: 700px;
    padding-top: 10rem;
}

.bildeHolder{}

@media screen and (min-width: 1800px) {
    .descriptionContainer{
        width: 1000px;
        margin-left: -150px;
    }
}    


.topline{
    color: #00FFFF;
    font-size: 22px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    
    margin-bottom: 5px;
    z-index: 3;

}

.heading{
    font-size: 42px;
    line-height: 1.1;
    font-weight: 600;
}


.descriptionContainer{
    padding: 50px;
}

@media screen and (min-width: 1800px) {
    .descriptionContainer{
        padding: 150px;
    }
}    
    
.hello{
    font-size: 20px;
    padding-bottom: 10px;
    font-family: 'Courier New', Courier, monospace;
}

.description{
    font-size: 18px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: 500;
    letter-spacing: 1.1px;
    padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
    
    .textBox{
        width: 360px;
    }

    .descriptionContainer{
        padding: 10px;
    }

    .description{
        font-size: 13px;
    }

    .buttonContainer{
        position: absolute;
        width: 30%;
        border: solid green 1px;
        height: 5px;
    }

    .b{
        position: absolute;
        width: 0;
       
    }

    .topline{
        font-size: 1.2rem;
    }

    .heading{
        font-size: 1.6rem;
    }
  
}

@media (max-height: 480px)  {
    .projectsContainer{
        height: 125vh;
    }
}
@media (max-height: 680px)  {
    .projectsContainer{
        height: 105vh;
    }
}



.linkUniversity{
    text-decoration: none;
}

.buttonContainer{
    padding-top: 350px;
    text-decoration: none;
    position: relative;
}

.button{
    background-color: rgba(0, 0, 0, 0.105);
    color: rgb(40, 39, 39);
    font-size: 1.2rem;
    transition: transform 0.1s ease;
    border-radius: 500px;
    padding: 15.5px;
    padding-right: 30px;
    padding-left: 30px;
    font-family: 'Courier New', Courier, monospace;
    margin: 40px;

    
}

.button::after, .button::before{
    content: "";
    position:absolute;
    opacity: 0.3;
    background-color: rgba(0, 0, 0, 0.105);
    width: 96%;
    height: 100%;
    left:0;
    bottom: 0;
    z-index: -1;
    transition: transform 0.3s ease;
    border-radius: 50px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;

}

.button:hover{
    transform: translate(-6px, -6px);
    color: white;
}

.button:hover::after{
    transform: translate(6px, 6px);

}

.button:hover::before{
    transform: translate(12px, 12px);

}

.b{

    position: absolute;
    z-index: -2;
    padding-left: 200px;
    height: 50%;
    left: 0;
    margin-bottom: -300px;
    margin-top: -350px;
    margin-left: -206px;
    border-radius: 500px;
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
}

