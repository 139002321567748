* {
    box-sizing: border-box;
}

.Contact_Container{
    width: 100%;
    height: 100%;
    background-color: black;
}

.rowbox_container{
    height: 90vh;
    width: 100%;
}

.rowbox_container::after{
    content: "";
    display: table;
    clear: both;
}

.columnBox_contact{
    float: left;
    height: 100%;
    width: 100%;
    position: relative;
}

.left_side{
    width: 35%;
}

.right_side{
    width: 60%;
}

.bildeContainer_contact {
    position: relative;
    width: 100%;
    height: 100%;
}

.bildeContainer_contact::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 6%, transparent 80%);
}

.bildeContainer_contact::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 1.0) , transparent 35%);
}

.bildeAvMeg_contact {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.bildeAvMeg_contact::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(20, 117, 150, 0.266), rgba(0, 0, 0, 0.559));
}

.headline_Contact {
    padding-top: 100px;
    padding-bottom: 2rem;
}



.topline_contact{
    color: aqua;
    font-family: fantasy;
    font-size: calc(0.7vh + 0.7vw);
    line-height: 1.6;
}

.heading_contact{
    font-size: calc(3.3vmin);
    letter-spacing: 0.1rem;
    
}

.meldingContainer_contact{
    justify-content: center;
    margin: auto;
    height: 100%;
    text-align: center;
    width: 55%;
    color: white;
    box-sizing: border-box;
    margin-top: calc(-1.5vw - 1vh);

}


.innholdOverdel_contact{
    font-family: 'Courier New', Courier, monospace;
    font-size: calc(0.7vw + 0.8vh);
    position: relative;
    width: 100%;
    display: flex;
    min-height: 6.6%;
    
}

.Contact_headline1{
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 6px;
}

.Contact_headline2{

    font-size: calc(0.8vw + 0.8vh); /*font-size: 1.5rem;*/
    align-self: flex-end;
    padding-left: 3rem;
    padding-bottom: 0.3rem;
    padding-top: calc(0.4vw + 0.4vh);
}

.rettStrek_contact{
    position: absolute;
    bottom: 0;
    width: 100%;
    border: solid whitesmoke 2px;
}

.inputContainer_contact{
    position: relative;
    display: grid;
    z-index: 3;
    align-items: end;
    width: 100%;
    box-sizing: border-box;

    font-size: large;

}

.overtekst_contact{
    padding: 10px;
    font-size: large;
    display: flex;
    flex-direction: end;
    padding-top: 2rem;
}

.textField_contact{
    font-size: large;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding-bottom: 0px;
    padding-left: 10px;
}

.meldingSection_contact{
    border-radius: 10px;
    font-size: large;
    padding-right: 15px;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;

}

.button_contact{
    margin-top: -15px;
}

@media screen and (max-width: 1600px) {
    .overtekst_contact{

        padding-top: 1.3rem;
    }

    .button_contact{
        margin-top: -5px;
    }

    .headline_Contact {
        padding-top: 90px;
        padding-bottom: 1rem;
    }

    .rowbox_container{
        height: 95vh;
        width: 100%;
    }

}

@media screen and (max-width: 1450px) {
    .button_contact{
        margin-top: 5px;
    }

    .rowbox_container{
        height: 98vh;
        width: 100%;
    }

    .meldingContainer_contact{
        margin-top: calc(-2vw - 2vh);
    }

}


@media screen and (max-width: 1200px) {

    .rowbox_container{
        height: calc(60vh + 50vw);
    }

    .meldingContainer_contact{
        max-width: 100%;
        margin-top: calc(10vw + 10vh);
    }


    .headline_Contact{
        padding-top: 20px;
        padding-bottom: 2rem;
        
    }

    .left_side{
        width: 0%;
    }

    .right_side{
        width: 100%;
    }
    /*
    .topline_contact{
        padding-top: 5px;
        font-size: 0.9rem;
    }
    */

    .heading_contact{
        /*font-size: 1.8rem;*/
    }

    .innholdOverdel_contact{
        min-height: 5%;
    }

    .Contact_headline1{
        font-size: 1.1rem;
    }

    .Contact_headline2{
        font-size: 1.1rem;
    }


    .inputContainer_contact{
        padding-top: 0rem;
        
    }

    .overtekst_contact{
        font-size: medium;
    }

    .textField_contact{
        font-size: medium;
    }

    .button_contact{
        margin-top: 0px;
    }
}

@media screen and (max-width: 800px) {

    .Contact_Container{
        overflow-x: hidden;
    }

    .rowbox_container{
        overflow-x: hidden;
        overflow: hidden;
    }


    .headline_Contact{
        padding-top: 10px;
        padding-bottom: 0.5rem;
    }

    .innholdOverdel_contact{
        min-height: 6%;
    }

    .Contact_headline1{
        font-size: 0.9rem;
    }

    .Contact_headline2{
        font-size: 0.9rem;
    }



    .left_side{
        width: 0%;
    }

    .right_side{
        width: 100%;
    }

    .bildeAvMeg_contact{
        margin-left: -30px;

    }

    .inputContainer_contact{
        padding-top: 0rem;

        bottom: 0;
        padding-right: 10px;
    }

    .rettStrek_contact{
        width: 90%;
    }

    .meldingContainer_contact{
        width: 80%;
        margin-top: calc(3vw + 3vh);
    }

    .overtekst_contact{
        font-size: small;
    }

    .textField_contact{
        font-size: small;
    }

    .button_contact{
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 10px;
    }
}


@media screen and (min-width: 1850px){
    .meldingContainer_contact{
        width: 50%;
    }
    .headline_Contact {
        padding-top: 140px;

    }
}


@media screen and (min-width: 2100px) {
    .rowbox_container{}

    .meldingContainer_contact{
        margin-top: calc(3vw + 2vh);
    }


    .headline_Contact{

        
    }
    
}

