

.quoteContainer{
    justify-content: center;
    padding: 100px;
    background-color: #00FFFF;
    margin: auto;
    text-align: center;
    opacity: 0.9;
    line-break: auto;
}

.quote{
    color: #555;
    font-family:sans-serif;
    font-weight: 100;
    letter-spacing: 1.2px;
}

.Alan{
    padding-top: 0.5rem;
    color: #666;
    font-weight: 600;
    font-family: fantasy;
}

@media screen and (max-height: 700px){


    .quote{
        font-size: 11px;
    }
    .Alan{
        font-size: 11px;
    }
}
@media screen and (max-height: 968px) and (min-height: 700px){
    .quote{
        font-size: 26px;
    }
    .Alan{
        font-size: 17px;
    }
}