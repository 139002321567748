
.footer_Container{
    height: 100%;
    width: 100%;
    background-color: black;
}

.rowbox_container_social{
    height: 100%;
    width: 100%;
    height: 25vh; /*20vh*/
}

.columnBox_social{
    float: left;
    height: 100%;
    position: relative;
    height: 70%;
    justify-content: center;
    text-align: center;
}

.left_side_social{
    width: 34%;
}
.middle_side_social{
    width: 33%;
}
.right_side_social{
    width: 33%;
}
.leftsideInfoHeadline{
    font-size: calc(0.8vh + 0.8vw);/*font-size: 1.5rem;*/
    padding-top: 0.6rem;
    color: white;
}

.leftSideInfo{
    color: white;
    padding-left: calc(0.45vh + 0.45vw);/*padding-left: 2.7rem;*/
    padding-top: 1rem;
    font-size: calc(0.53vw + 0.53vh);/*font-size: 1.1rem;*/


}

.middleSideInfo{
    color: white;
    font-size: calc(0.50vh + 0.50vw);/*font-size: 1rem;*/
    text-align: center;
    justify-content: center;
    margin-top: calc(-0.7vh + -0.7vw);/*-1.7rem;*/
    letter-spacing: 0.1rem;
}
.createdV2{
    padding-right: calc(2vh + 2vw);
    padding-left: calc(2vh + 2vw);
    letter-spacing: calc(0.2vh + 0.2vw); /*5px*/
    margin-top: calc(0.7vw + 0.7vh);/*padding-top: 3.2rem;*/
    width: 100%;

}

.rightSideInfo{
    color: white;
    padding: calc(0.20vh + 0.13vw) calc(0.8vh + 0.9vw); /*0.45rem 0.6rem*/
    margin-left: calc(1vh + 0.5vw);
    width: calc(2.9vh + 2.9vw);/*3.5rem;*/
}
.linkedinDesign{

}
.githubDesign{
    margin-left: -20px;
    
}

.social_h2{
    width: 100%;
    padding-top: 0.6rem;
    font-size: calc(0.8vh + 0.8vw);/*font-size: 1.5rem;*/
}

.leftSideContainer{
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
}

.middleSideContainer{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 30%;
    margin-top: calc(3vh + 3vw);
    right: 0;
    left: 0;
    justify-content: center;

}

.rightSideContainer{
    position: absolute;
    bottom: 0;
    width: 80%;
    height: 50%;
}

@media screen and (min-height: 1300px) {
    .footer_Container{
        position: relative;
        height: 20%;
    }



}

@media screen and (max-width: 1200px) {
    /*
    .rowbox_container_social{
        height: 30vh;
    }*/

    .left_side_social{
        width: 25%;
    }
    .middle_side_social{
        width: 45%;
    }
    .right_side_social{
        width: 30%;
    }

    .middleSideContainer{
        margin-left: 15px;
        margin-bottom: calc(0.5vh + 0.8vw);
    }
    
    .social_h2{
        padding-left: 0rem;
        font-size: calc(1vh + 1vw);
        
    }

    .middleSideInfo{
        display: flex;
        font-size: calc(0.60vh + 0.60vw);
        align-items: center;
        justify-content: center;
    
    }

    .leftsideInfoHeadline{
        font-size: calc(0.95vh + 0.95vw);/*font-size: 1.5rem;*/
    }

    .leftSideInfo{
        font-size: calc(0.7vw + 0.7vh);
    }

    .createdV2{
        padding-right: 0;
        font-size: calc(0.1vh+ 0.1vw);
        display: flex;
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
        letter-spacing: calc(0.1vh + 0.1vw); /*5px*/
        margin-top: calc(0.3vw + 0.3vh);/*padding-top: 3.2rem;*/
    }
}
/*
@media screen and (max-width: 768px) {
    /*
    .rowbox_container_social{
        height: 55vh;
    }


    .leftSideInfo{
        padding-top: 1rem;
        /*font-size: 0.9rem;
    }
   
    .middleSideInfo{

        font-size: calc(0.65vh + 0.65vw);
    
    }


    /*
    .rightSideInfo{
        font-size: 1rem;
        padding-top: 2rem;
        width: 40%;
    }

    
}

*/

