
.html{
    scroll-behavior: smooth;
    overflow-y: hidden;
    overflow-x: hidden;
}

.body{
    font-family: sans-serif;
}



.aboutMecontainer{
    /*background-image: url("../../images/ProgImg/Kotlin_Icon.svg.png");
    background-color: rgba(237,239,244,255);
    background-image: url("../../images/ProgImg/Kotlin_Icon.svg.png");
    background-blend-mode:color;
    background-position-x: right;*/
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(237,239,244,255);
}

.aboutMeTextBoxcontainer{
    background-color: #fff;
    width: 70%;
    height: 70%;
    margin-top: -70px;
    max-height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -30px 30px 10px rgba(0, 0, 0, 0.4);
}

.aboutMeTextBox{
    width: 90%;
    height: 80%;
    position: relative;
}

.aboutTopline{
    width: 100%;
    text-align: center;
    font-family: sans-serif;
}


.headlineCont{
    position: relative; 
    justify-content: center;
    display: block;
    flex-direction: row;
    width: 100%;
    text-align: center;
}


.rettStrekAbout{
    /*
    position: relative;
    margin-bottom: 7.5px;
    width: 0.5rem;
    display: inline-block;
    border: solid #444 0.0207in;
    transition: border-width 0.4s ease-out, width 0.5s ease-out; /* Overgang for bredde og grenselinjens tykkelse */

}

.rettStrekAbout.animate {
    /*
    width: 7.5rem; /* Angi ønsket bredde 
    border-width: 0.0207in; Øk grenselinjens tykkelse */

}

.splitAbout{
    height: 100%;
}

.left{
    float:left;
    height:100%;
    width: 60%;
    margin:0;
    bottom: 0;

}

.right {
    float:right;
    height:100%;
    width: 40%;
    margin:0;
}

.centered {
    height: 100%;
    width: 90%;
    padding: 25px;
}

.OmMeg{
    position: absolute;
    width: 50%;
    height: 50%;
    bottom: 0;
    top: 40%;
    padding: 10px;
}

.toplineAbout{
    font-size: 1.7rem;
    letter-spacing: 1.4px;
    padding-bottom: 8px;
    margin-top: -6px;
    color: #444;
    font-weight: 400;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.descriptionContainerAbout{
    padding-bottom: 10px;
}
.descriptionAbout{
    padding-bottom: 20px;
    /*font-size: calc(0.50vh + 0.50vw);/*1.1rem;
    letter-spacing: calc(0.05vh + 0.05vw);/*1.5px;
    word-spacing: calc(0.02vh + 0.02vw);/*0.02rem;
    max-width: calc(60vh + 80vw);/*80rem;*/
    color: #666;
    /*
    /*font-family: sans-serif;*/

}

.softSkillsContainer{
    position: absolute;
    width: 40%;
    height: 50%;
    bottom: 0;
    margin: 0;
    top: 40%;

}

.skillsTopLineAbout{
    font-size: 1.7rem;
    letter-spacing: 1.4px;
    color: #444;
    font-weight: 400;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.softSkills{
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    align-items: center;
    
}


.skill{
    border: solid 1px rgb(231, 231, 227);
    background-color: rgb(231, 231, 227);
    padding: 12px;
    margin-top: calc(0.5vw + 0.5vh);
    margin-right: calc(0.35vw + 0.35vh);
    margin-bottom: 20px;
    border-radius: 5px;
}

.softSkilltext{
    top: 0;
    margin: auto;
    text-align: center;
    justify-content: center;
    /*
    padding-bottom: 10px;
    padding: 1px;
    margin-top: -12px;
    */
    font-family: sans-serif;
    font-weight: 600;
    color: #666 ;
    font-size: calc(0.5vw + 0.45vh);
}

.buttonToContactContainer{
    position: relative;
    font-size: calc(0.5vh + 0.6vw);
}


@media screen and (max-height: 1200px) {
    .aboutMeTextBoxcontainer{
        width: 82%;
    }
    
}

@media screen and (min-width: 1800px) {
    .aboutMeTextBoxcontainer{
        height: 70%;
    }
}

@media (max-height: 1100px){ 

    .aboutMeTextBoxcontainer{
        width: 85%;
        height: 92%;
    }

    .OmMeg{
        top: 30%;
    }

    .softSkillsContainer{
        top: 30%;
    }

    .toplineAbout{
        font-size: 1.1rem;
    }

    .skillsTopLineAbout{
        font-size: 1.1rem;
    }

    .descriptionAbout{
        font-size: calc(0.6vh + 0.62vw);
    }

    .buttonToContactContainer{
        font-size: calc(0.6vh + 0.6vw);
    }


    .softSkilltext{
        font-size: calc(0.6vw + 0.45vh);
    } 
}

@media screen and (max-width: 1650px){
    .softSkillsContainer{
        width: 39%;
    }

    .skill{
        margin-bottom: 15px;
    }

}


@media screen and (max-width: 1450px) {
    .softSkillsContainer{
        width: 37%;
    }

    .skill{
        margin-bottom: 10px;
    }

}


@media screen and (max-width: 1100px) {
    .aboutToplineP1{
        /*font-size: 1rem;*/
    }

    .aboutMeHeadline{
        /*font-size: 1.8rem;*/
    }

    .aboutMeInfo{
        /*
        font-size: 0.9rem;*/
    }

    .OmMeg{
        top: 30%;
    }

    .softSkillsContainer{
        top: 30%;
        width: 30%;
    }


    .toplineAbout{
        font-size: 1rem;
    }

    .descriptionAbout{
        /*font-size: calc(0.6vh + 0.6vw);*/
    }

    .skillsTopLineAbout{
        font-size: 1rem;
    }

    .softSkilltext{
        font-size: calc(0.6vw + 0.45vh);
    }

    .skill{

        padding: 6px;
        margin-top: calc(0.2vw + 0.2vh);
        margin-right: calc(0.15vw + 0.15vh);
        margin-bottom: 10px;
        border-radius: 5px;
    }
}

@media screen and (max-width: 1808px) {
    
    .softSkills{
        grid-template-columns: repeat(2, 1fr);
    }

    .OmMeg{
        top: 30%;
    }

    .softSkillsContainer{
        top: 30%;

    }

    .toplineAbout{
        font-size: 1.2rem;
    }

    .descriptionAbout{
        /*font-size: calc(0.vh + 0.9vw);*/
    }

    .skillsTopLineAbout{
        font-size: 1.2rem;

    }
    .aboutMeTextBoxcontainer{
        width: 80vw;
        height: 70%;
    }
    

    .skill{
        width: 90%;
    }

    .softSkilltext{
        font-size: calc(0.7vw + 0.5vh);
    }
}


@media screen and (max-width: 768px) {
    .softSkills{
        grid-template-columns: repeat(1, 1fr);
    }

    .skillsTopLineAbout{
        font-size: 0.7rem;

    }

    .toplineAbout{
        font-size: 0.7rem;
    }

    
}