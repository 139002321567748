
        
        
.Projects_test_Container{
    background-color: white;
    height: 100%;
    scroll-behavior: smooth;
}

.rowHolder{
    padding-top: 10rem;
    overflow: hidden;
}

.rowBoxes{
    display: flex;
    width: 100%;
    height: 100vh;

}

.columnBox{
    flex: 50%;
    padding: 10px;
    position: relative;
    font-size: 1.3rem;
    width: 15rem;
}

.topline_logo_container{
    justify-content: center;
    margin: 0;
    text-align: center;
    font-size: 1.7rem;
    font-family: cursive;
    font-weight: 100;
    letter-spacing: 1.7px;
    padding-bottom: 30px;
}

.logo{
    width: 20%;
}

.firstAppDescription{
    margin-left: 100px;
    width: 45%;
    padding-top: 5rem;
    letter-spacing: 1.3px;
    font-weight: 100;
}

.secondAppDescription{
    margin-left: 300px;
    width: 45%;
    padding-top: 5rem;
    letter-spacing: 1.3px;
    font-weight: 100;

}

.thirdAppDescription{
    width: 45%;
    margin-left: 100px;
    padding-top: 5rem;
    letter-spacing: 1.3px;
    font-weight: 100;
}


.appInfo{
    padding-top: 0;
}

.jetpack_info{
    height: 50%;
}

.appHomePage_Headline{
    justify-content: center;
    margin: auto;
    text-align: center;
    font-size: 1.5em;
    color: #555;
}

.appSearch_Headline{
    justify-content: center;
    margin: auto;
    text-align: center;
    font-size: 1.5em;
    color: #555;
}

.welcome_images{
    width: 100%;
}

.intro1_image{
    position: absolute;
    width: 35%;
    justify-content: center;
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
}

.intro2_image{
    position: absolute;
    right: 0;
    width: 30%;
    padding-top: 250px;
    margin-right: 100px;
    z-index: -1;
}

.intro3_image{
    position: absolute;
    width: 30%;
    left: 0;
    padding-top: 250px;
    margin-left: 100px;
    z-index: -1;
}

.homeImage_Container{
    width: 100%;
    position: relative;
}

.homeImage{
    z-index: 33;
    width: 40%;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-left: 3rem;
    margin-top: 7rem;
}


.sokeResultat_image{
    position: absolute;
    width: 30%;
    right: 0;
}

.severalSearch_image{
    width: 30%;
}

.clickedSearch_image{
    position:absolute;
    width: 30%;
    bottom: 0;
    justify-content: center;
    margin: auto;
    text-align: center;
    right: 0;
    left: 0;
}

.settings_Container{
    width: 100%;
    position: relative;
    
}

.setting_Img{
    width: 40%;
    left: 0;
    z-index: -1;
}

.darkMode_Img{
    width: 30%;
    z-index: -1;
}

.colorBlind_Img{
    width: 30%;
    z-index: -1;

}

.BottomSectionProjects{
    width: 100%;
    text-align: left;



}

.backToProjectsBtn{
    margin: auto;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: rgb(75, 197, 238);
    margin-left: 100px;
    padding: 50px;
    transition: 0.2s;
    cursor: pointer;
    font-size: 1.2em;
}

.backToProjectsBtn:hover{
    color: lightblue;
}



@media screen and (min-width: 1800px) {

    .columnBox{
        font-size: 1.4rem;
    }

    .firstAppDescription{
        width: 30%;
    }

    .secondAppDescription{
        margin-left: 300px;
        width: 35%;
    }
    
    .thirdAppDescription{
        width: 30%;
    }
}

@media screen and (max-height: 1000px) {
    
    .columnBox{
        font-size: 0.9rem;
        width: 12rem;
    }

   .rowBoxes{
       height: 90vh;
   }

   .jetpack_info{
       height: 30vh;
   }
}




@media all and (max-height: 768px)  {


    .columnBox{
        width: 10rem;
        font-size: 0.7rem;
        overflow: hidden;
    }

    .rowHolder{
        padding-top: 10rem;
        overflow-x: hidden;
    }

    .rowBoxes{
        height: 95vh;
    }

    .appInfo{
        padding-top: 0;
    }

    .jetpack_info{
        height: 25vh;
    }

    .firstAppDescription{
        margin-left: 10px;
        width: 70%;
        padding-top: 2rem;
    }

    .secondAppDescription{
        margin-left: 50px;
        width: 70%;
        padding-top: 2rem;
    }

    .thirdAppDescription{
        margin-left: 10px;
        width: 70%;
        padding-top: 2rem;
    }

    .welcome_images{
        margin-top: 100px;
    }

    .intro1_image{
        width: 55%;
    }

    .intro2_image{
        width: 45%;
        margin-right: 0px;
    }
    .intro3_image{
        width: 45%;
        margin-left: 0px;
    }

    .homeImage{
        width: 65%;
        margin-top: 200px;
    }

    .sokeResultat_image{
        margin-bottom: 200px;
        bottom: 0;
        width: 50%;

    }

    .severalSearch_image{
        z-index: -1;
        margin-top: 200px;
        width: 50%;
    }

    .clickedSearch_image{
        width: 50%;

    }

    

    .setting_Img{
        width: 55%;
    }

    .darkMode_Img{
        width: 45%;
    }

    .colorBlind_Img{
        right: 0;
        margin-left: 80px;
        width: 50%;
    }

    .columnSettings{
        height: 80vh;
        z-index: -1;
        margin-top: 100px;
    }
}


@media (max-width: 770px)  {


    .columnBox{
        width: 10rem;
        font-size: 0.7rem;
        overflow: hidden;
    }

    .rowHolder{
        padding-top: 10rem;
        overflow-x: hidden;
    }

    .rowBoxes{
        height: 95vh;
    }

    .appInfo{
        padding-top: 0;
    }

    .jetpack_info{

        height: 15vh;
    }

    .firstAppDescription{
        margin-left: 10px;
        width: 70%;
        padding-top: 2rem;
    }

    .secondAppDescription{
        margin-left: 50px;
        width: 70%;
        padding-top: 2rem;
    }

    .thirdAppDescription{
        margin-left: 10px;
        width: 70%;
        padding-top: 2rem;
    }

    .welcome_images{
        margin-top: 100px;
    }

    .intro1_image{
        width: 55%;
    }

    .intro2_image{
        width: 45%;
        margin-right: 0px;
    }
    .intro3_image{
        width: 45%;
        margin-left: 0px;
    }

    .homeImage{
        width: 65%;
        margin-top: 200px;
    }
    

    .sokeResultat_image{
        margin-bottom: 200px;
        bottom: 0;
        width: 50%;

    }

    .severalSearch_image{
        z-index: -1;
        margin-top: 200px;
        width: 50%;
    }

    .clickedSearch_image{
        width: 50%;

    }

    

    .setting_Img{
        width: 55%;
    }

    .darkMode_Img{
        width: 45%;
    }

    .colorBlind_Img{
        right: 0;
        margin-left: 80px;
        width: 50%;
    }

    .columnSettings{
        height: 80vh;
        z-index: -1;
        margin-top: 100px;
    }
}

